// import HomeBanner from "../components/Home/HomeBanner/HomeBanner";
import Section from "../components/Misc/section/Section";
// import HomeDemo from "../components/Home/HomeDemo/HomeDemo";
import dynamic from "next/dynamic";
import { GoogleOAuthProvider } from "@react-oauth/google";
// Dynamic import for GoogleOAuthProvider with ssr: false
//const GoogleOAuthProvider = dynamic(
// () => import("@react-oauth/google").then(mod => mod.GoogleOAuthProvider),
//  { ssr: false }
//);
// Landing page compoennt 
// import Header from "../components/Misc/header/Header";
import Hero from './../components/Pages/LandingPage/Hero/hero';
import Landscaping from "./../components/Pages/LandingPage/Landscaping/Landscaping";
// import Creative from "../components/Pages/LandingPage/ResultsShowcase/ResultsShowcase";
// import About from "./../components/Pages/LandingPage/About/about";
// import Furnishedroom from "./../components/Pages/LandingPage/FurnishedRoom/Furnishedroom";
// import HomePageEditor from "../components/Pages/LandingPage/HomePageEditor/HomePageEditor";
const Creative = dynamic(() =>
  import("../components/Pages/LandingPage/ResultsShowcase/ResultsShowcase")
);
const About = dynamic(() =>
  import("./../components/Pages/LandingPage/About/about")
);
const HomePageEditor = dynamic(() =>
  import("../components/Pages/LandingPage/HomePageEditor/HomePageEditor")
);

export default function Home() {
  return (
    <div>
      <Section>
        <GoogleOAuthProvider clientId="1043826682338-e42qp7a08emlpund6dqp1m1slq8v5saj.apps.googleusercontent.com">
          {/* <Header /> */}
          <Hero />
          <Landscaping />
          <HomePageEditor />
          <About />
          <Creative />
          {/* <Furnishedroom /> */}
        </GoogleOAuthProvider>
      </Section>
    </div>
  );
}
