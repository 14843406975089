
import LayoutFillImg from "../../../Misc/LayoutFillImg/LayoutFillImg";
import Button from "../../../Misc/button/Button";
import Section from "../../../Misc/section/Section";
import Text from "../../../Misc/text/Text";
import { useRouter } from "next/router";
import styles from "./hero.module.scss";


const Hero = () => {
  const router = useRouter();
  return (
    <Section className={styles.heromainContainer}>
      <div className={styles.hero_corner}>
        <LayoutFillImg src="/assets/cornerhero.png" alt="corner" />
      </div>

      <div className={styles.heroContainer}>
        <div className={styles.lefttext}>
          <h2>
            REIMAGINE <br /> YOUR HOME
          </h2>
          {/* <div>
            <LayoutFillImg
              src="/assets/heroleftimage.jpg"
              alt="rounded image"
            />
          </div> */}
        </div>
        <div className={styles.heroImage_Container}>
          <div className={styles.heroImage}>
            <LayoutFillImg src="/assets/hero.png" alt="hero" lazyLoad={false} quality={100}/>
          </div>

          <div className={styles.imagecircles}>
            <div className={styles.imagecircle}>
              <div className={styles.x_icon}>
                <LayoutFillImg src="/assets/design.svg" alt="design" />
              </div>
              <Text>Custom space design using AI</Text>
            </div>

            <div className={styles.imagecircle}>
              <div className={styles.x_icon}>
                <LayoutFillImg
                  src="/assets/development.svg"
                  alt="development"
                />
              </div>
              <Text>Try products in your area</Text>
            </div>

            <div className={styles.imagecircle}>
              <div className={styles.x_icon}>
                <LayoutFillImg src="/assets/marketing.svg" alt="marketing" />
              </div>
              <Text>Used by a network of contractors</Text>
            </div>

            <div className={styles.imagecircle}>
              <div className={styles.x_icon}>
                <LayoutFillImg src="/assets/digital.svg" alt="degital" />
              </div>
              <p>Results in under 1 minute</p>
            </div>
          </div>
        </div>
        <div className={styles.righttext}>
          <p>
            Redesign your outdoor home space to match your vision, or let our AI show you
            inspiring designs in seconds.
          </p>
          {/* <b>OR</b> */}
          <p onClick={()=> router.push('/auth')}>Try with a sample image</p>
          <div className={styles.btn_arrow}>
            <Button variant={"primary"} className={styles.heroBtn} link="/auth">Sign Up & Upload</Button>
            <div className={styles.round_arrow}>
              <LayoutFillImg src="/assets/arrow.svg" alt="arrow" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.hero_xs}>
        <div className={styles.hero_ellipes1}>
          <LayoutFillImg src="/assets/circle.svg" alt="ellipes" />
        </div>
        <div className={styles.circle_x}>
          <div className={styles.circle_x1}>
            <div className={styles.x1_image}>
              {/* <LayoutFillImg src="/assets/circleimage.svg" alt="image" /> */}
            </div>
            <div className={styles.x1_text}>
              <b>1 minute</b>
              <p>
                To Create <br /> a Design
              </p>
            </div>
          </div>

          <div className={styles.circle_x1}>
            <div className={styles.x1_image}>
              {/* <LayoutFillImg src="/assets/circleimage2.svg" alt="image" /> */}
            </div>
            <div className={styles.x1_text}>
              <b>200+</b>
              <p>
                Of your favorite <br /> products
              </p>
            </div>
          </div>

          <div className={styles.circle_x1}>
            <div className={styles.x1_image}>
              {/* <LayoutFillImg src="/assets/circleimage3.svg" alt="image" /> */}
            </div>
            <div className={styles.x1_text}>
              <b>1</b>
              <p>
                Shot <br /> transformation
              </p>
            </div>
          </div>
        </div>
        <div className={styles.hero_ellipes2}>
          <LayoutFillImg src="/assets/circle.svg" alt="ellipes" />
        </div>
      </div>
    </Section>
  );
};

export default Hero;
